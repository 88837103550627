import type { ISlide } from "~/lib/slide";
import { useUniqueId } from "~/utils/useUniqueId";

export const slider: ISlide[] = [
    { id: 1, src: "/img/slider/home_slider_image_1.webp", alt: "Decoration slide 1", heroSlider: true },
    { id: 2, src: "/img/slider/home_slider_image_3.webp", alt: "Decoration slide 2", heroSlider: true },
    { id: 3, src: "/img/slider/home_slider_image_5.webp", alt: "Decoration slide 3", heroSlider: true },
    { id: 4, src: "/img/slider/home_slider_image_2.webp", alt: "Decoration slide 4", heroSlider: true },
    { id: 14, src: "/img/slider/patient_with_picking_implants_slider.webp", alt: "Patient with picking implants slider", heroSlider: true },
    { id: 6, src: "/img/slider/home_slider_image_7.webp", alt: "Decoration slide 6", heroSlider: true },
    { id: 7, src: "/img/slider/home_slider_image_6.webp", alt: "Decoration slide 7", heroSlider: true },
    { id: 8, src: "/img/slider/home_slider_image_10.webp", alt: "Decoration slide 8", heroSlider: true },
    { id: 9, src: "/img/slider/home_slider_image_9.webp", alt: "Decoration slide 9", heroSlider: true },
    { id: 10, src: "/img/slider/breast_implants_slider.webp", alt: "Breast implants slider", heroSlider: true },
    { id: 11, src: "/img/slider/home_slider_image_11.webp", alt: "Decoration slide 11", heroSlider: true },
    { id: 12, src: "/img/slider/home_slider_image_12.webp", alt: "Decoration slide 12", heroSlider: true },
    { id: 5, src: "/img/slider/home_slider_image_8.webp", alt: "Decoration slide 5", heroSlider: true }
];

export const clinicImages: ISlide[] = [
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_1.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_2.webp", alt: "Nurses on the launch" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_3.webp", alt: "Gynecology room" },
    { id: useUniqueId(), src: "/img/clinic/breast_implants_dr.webp", alt: "Breast implants dr" },
    { id: useUniqueId(), src: "/img/clinic/what_are_the_best_breast_implants.webp", alt: "What are the best breast implants" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_4.webp", alt: "Front photo of clinic" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_5.webp", alt: "Front photo of clinic" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_6.webp", alt: "Surgery room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_7.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_8.webp", alt: "Consulting room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_9.webp", alt: "Consulting room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_10.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_11.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_12.webp", alt: "Surgery room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_13.webp", alt: "Stomatology room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_14.webp", alt: "Gynecology room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_15.webp", alt: "Gynecology room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_16.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_17.webp", alt: "Stomatology room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_18.webp", alt: "Stomatology room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_19.webp", alt: "Reception photo" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_20.webp", alt: "Reception photo" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_21.webp", alt: "Consulting room" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_22.webp", alt: "Clinic hole" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_23.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_24.webp", alt: "Medical equipment" },
    { id: useUniqueId(), src: "/img/clinic/allmedica_image_25.webp", alt: "Stomatology room" }
];
